import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <section className="bg2 footer" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <img
              src="/img/logo.png"
              alt="header-Logo"
              className="logo logo_lite mb-2 "
            />
            <img
              src="/img/logo.png"
              alt="header-Logo"
              className="logo logo_dark mb-2"
            />

            <p>
              {t(
                "Trade with a trusted broker today, Enjoy flexible access to more than 17,000 global markets, with reliable execution"
              )}
              .
            </p>
            <ul className="social_icon ">
              <li>
                <a href="#">
                  <i className="bi bi-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bi bi-telegram"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bi bi-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="bi bi-pinterest"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-8">
            <div className="row">
              <div className="col-md-4">
                <div className="nav_link">
                  <h4>{t("Markets")}</h4>
                  <ul className="">
                    <li className="">
                      <a
                        className=""
                        href="https://www.afgx.com/en/market/forex/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("Forex")}
                      </a>
                    </li>
                    <li className="">
                      <a
                        className=""
                        href="https://www.afgx.com/en/market/stock/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("Equity")}{" "}
                      </a>
                    </li>

                    <li className="">
                      <a
                        className=" "
                        href="https://www.afgx.com/en/market/cryptocurrency/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("Crypto")} {t("Currencies")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nav_link">
                  <h4>{t("Trading")}</h4>
                  <ul>
                    <li>
                      <a
                        href="https://www.afgx.com/en/trade/trading-rules/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        {t("Trading Rules")}{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.afgx.com/en/trade/margin-rules/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        {t("Margin Rules")}{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.afgx.com/en/platform/trading-platform/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        {t("Trading Platform")}{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.afgx.com/en/partnership/introducing-broker/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("Introducing brokers")}{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="nav_link">
                  <h4> {t("About")} </h4>
                  <ul>
                    <li>
                      <a
                        href="https://www.afgx.com/en/company/about-us/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        {t("About Us")}{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.afgx.com/en/company/contact-us/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("Contact Us")}{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.afgx.com/en/company/terms-condition/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        {t("Terms & Condition")}{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.afgx.com/en/company/privacy-policy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        {t("Privacy Policy")}{" "}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="copy-right bg2 p-3 text-center br10">
        <small>version 1.1.0</small>
      </div>
    </section>
  );
};

export default Footer;
