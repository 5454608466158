import React, { Component } from "react";
import { baseUrl } from "../constant/BaseUrl";
import axios from "axios";

class TradingViewChart extends Component {
  constructor(props) {
    super(props);
    this.chartContainerRef = React.createRef();
    this.tvWidget = null; // Initialize a reference to the TradingView widget
  }

  componentDidMount() {
    // this.checkExchange();
    this.initChart();
  }

  // checkExchange = () => {
  //   const Url = window.location.pathname.split("/");

  //   let sendCoinData = {
  //     firstCoin: Url[2],
  //     secondCoin: Url[3],
  //   };

  //   axios.post(baseUrl + "/pair-info", sendCoinData).then((respData) => {
  //     var apiResp = respData.data;
  //     var apiRespData = apiResp.data;
  //     if (apiRespData.pairId) {
  //       // axios
  //       //   .post(baseUrl + "/frontapi/checkpairexchange", sendCoinPair)
  //       //   .then((respData) => {
  //       //     var apiExchangeResp = respData.data;
  //       //     if (apiExchangeResp.success === true) {
  //       //       localStorage.setItem("exType", apiExchangeResp.data.exchange);
  //       //     } else {
  //       //       localStorage.setItem("exType", "notExchange");
  //       //     }
  //       //   });
  //       this.initChart();
  //     }
  //   });
  // };

  componentDidUpdate(prevProps) {
    // if (localStorage.getItem("theme") === "true") {
    //   this.theam = "dark";
    // } else {
    //   this.theam = "light";
    // }
    // if (this.tvWidget) {
    //   this.tvWidget.onChartReady(() => {
    //     this.tvWidget.changeTheme(this.theam);
    //   });
    // }
    if (this.props.theme) {
      this.tvWidget.onChartReady(() => {
        this.tvWidget.changeTheme(this.props.theme);
      });
    }
    const { firstCoin, secondCoin } = this.props;
    if (
      firstCoin != prevProps.firstCoin ||
      secondCoin != prevProps.secondCoin
    ) {
      this.updateSymbol();
    }
  }

  initChart() {
    const { firstCoin, secondCoin } = this.props;
    const pairName = firstCoin + secondCoin;
    // Initialize the TradingView widget with the initial symbol

    this.tvWidget = new window.TradingView.widget({
      fullscreen: false,
      symbol: pairName,
      interval: "1",
      container: this.chartContainerRef.current,
      height: "100%",
      width: "100%",
      //   custom_css_url:"/css/TradingView.css",
      datafeed: new window.Datafeeds.UDFCompatibleDatafeed(baseUrl),
      library_path: "/charting_library/",
      locale: "en",

      // disabled_features: ["use_localstorage_for_settings","header_widget"],
      disabled_features: ["use_localstorage_for_settings"],
      enabled_features: ["study_templates"],
      charts_storage_url: "",
      charts_storage_api_version: "1.1",
      client_id: "om",
      user_id: "public_user_id",
      theme: "dark",
      timezone: "Asia/Kolkata",
      // toolbar_bg: '#041528',
      overrides: {
        "mainSeriesProperties.style": 8,
        // "paneProperties.background": "#041528",
        // "paneProperties.backgroundType": "solid",
        // "crossHairProperties.color":"red"
      },
    });
    this.tvWidget.onChartReady(() => {
      this.updateSymbol();
    });
  }

  updateSymbol() {
    // Update the symbol property of the existing widget instance
    if (this.tvWidget) {
      const { firstCoin, secondCoin } = this.props;
      const pairName = firstCoin + secondCoin;
      this.tvWidget &&
        this.tvWidget.setSymbol(pairName, "1", () => {
          console.log("Symbol changed successfully");
        });
    }
  }

  componentWillUnmount() {
    if (this.tvWidget) {
      this.tvWidget.remove();
    }
  }

  render() {
    return <div ref={this.chartContainerRef} id="tv_chart_container"></div>;
  }
}

export default TradingViewChart;
